<template>
  <div
    :class="['market-configurations-bulk-popup', {'close-popup': closePopupOpen}]"
    ref="configurationsPopupRef"
  >
    <div class="market-configurations-header">
      <div class="market-configurations-name">
        {{ modalTitle }}
      </div>
      <div class="market-configurations-date">
        Changes will overwrite previously set configuration for selected markets and their rulesets
      </div>
    </div>
    <div class="market-configuration-ps">
      <div class="market-configurations-body">
        <BulkResultingConfigurationTab
          v-if="configurationType === 'resulting'"
          class="market-configurations-content"
          :resulting-configuration="resultingConfiguration"
          @update:resulting-configuration="setResultingConfiguration"
        />
        <BulkRiskConfigurationTab
          v-else-if="configurationType === 'risk'"
          class="market-configurations-content"
          :limit-configuration-type="limitConfigurationType"
          @update:risk-configuration="setRiskConfiguration"
        />
        <BulkLineConfigurationTab
          v-else-if="configurationType === 'line'"
          class="market-configurations-content"
          @update:template-configuration="setTemplateConfiguration"
        />
        <BulkHoldConfigurationTab
          v-else-if="configurationType === 'hold'"
          class="market-configurations-content"
          @update:hold-configuration="setHoldConfiguration"
        />
      </div>
    </div>
    <div class="market-configurations-footer">
      <div
        class="market-configurations-button"
        @click="closePopup"
      >
        Dismiss
      </div>
      <div
        :class="['market-configurations-button save', { 'disabled': isSaveConfigurationDisabled || savingConfiguration}]"
        @click="saveConfiguration"
      >
        Save
      </div>
    </div>
    <div
      class="close-popup-wrapper"
      v-if="closePopupOpen"
      ref="closePopupRef"
    >
      <div class="unsaved-changes-header">
        Unsaved changes
      </div>
      <div class="unsaved-changes-message">
        You have unsaved changes, do you want to continue?
      </div>
      <div class="unsaved-changes-buttons">
        <div
          class="unsaved-changes-button"
          @click="() => { closePopupOpen = false; }"
        >
          Back
        </div>
        <div
          class="unsaved-changes-button yes"
          @click="closeSecondPopup"
        >
          Yes
        </div>
      </div>
    </div>
    <div
      class="close-current-popup-bg-overlay"
      v-if="closePopupOpen"
    />
  </div>
  <div
    class="close-popup-bg-overlay"
    v-if="closePopupOpen"
  />
</template>

<script>
import {
  ref, computed, onMounted, onBeforeUnmount,
} from 'vue';
import { useStore } from 'vuex';
import { onClickOutside } from '@vueuse/core';
import {
  filter, map,
} from 'lodash';
import BulkResultingConfigurationTab from './BulkResultingConfigurationTab';
import BulkRiskConfigurationTab from './BulkRiskConfigurationTab';
import BulkLineConfigurationTab from './BulkLineConfigurationTab';
import BulkHoldConfigurationTab from './BulkHoldConfigurationTab';

export default {
  components: {
    BulkResultingConfigurationTab,
    BulkRiskConfigurationTab,
    BulkLineConfigurationTab,
    BulkHoldConfigurationTab,
  },
  props: {
    sportTemplateId: {
      required: true,
      type: String,
      default: () => '',
    },
    configurationType: {
      required: true,
      type: String,
      default: () => '',
    },
  },
  emits: [
    'closePopup',
  ],
  setup(props, { emit }) {
    const store = useStore();
    const selectedMarkets = computed(() => store.getters.selectedMarkets);
    const marketHoldConfiguration = ref(null);
    const resultingConfiguration = ref(null);
    const marketLimitConfiguration = ref(null);
    const templateConfiguration = ref(null);

    const unsavedChanges = ref(false);
    const closePopupOpen = ref(false);
    const closePopupRef = ref(false);
    const savingConfiguration = ref(false);
    const lineMarkets = computed(() => filter(selectedMarkets.value, (selectedMarket) => selectedMarket.isLineMarket));
    const hasLineMarkets = computed(() => !!lineMarkets.value.length);
    const hasRegularMarkets = computed(() => lineMarkets.value.length !== selectedMarkets.value.length);
    const isSaveConfigurationDisabled = computed(() => savingConfiguration.value || !unsavedChanges.value);
    const modalTitle = computed(() => {
      switch (props.configurationType) {
      case 'resulting':
        return 'Resulting configuration';
      case 'line':
        return 'Line configuration';
      case 'hold':
        return 'Hold configuration';
      case 'risk':
        return 'Risk configuration';
      default:
        return 'N/A';
      }
    });

    const limitConfigurationType = computed(() => {
      if (hasRegularMarkets.value) return 'SIMPLE_TIERED';
      return 'MAIN_ALT_TIERED';
    });

    const closePopup = () => {
      if (unsavedChanges.value) {
        closePopupOpen.value = true;
        return;
      }
      emit('closePopup');
    };

    const closeSecondPopup = () => {
      unsavedChanges.value = false;
      closePopup();
    };

    const configurationsPopupRef = ref(null);
    onClickOutside(configurationsPopupRef, () => {
      if (closePopupOpen.value) return;
      emit('closePopup');
    });

    onClickOutside(closePopupRef, () => {
      closePopupOpen.value = false;
    });

    function generateConfig() {
      const payload = {
        sportLogicTemplateIds: [...map(store.getters.selectedMarkets, (item) => item.sportsLogicTemplateId)],
      };

      switch (props.configurationType) {
      case 'resulting':
        payload.resultingConfiguration = resultingConfiguration.value;
        break;
      case 'line':
        payload.templateConfiguration = templateConfiguration.value;
        break;
      case 'hold':
        payload.marginStrategyConfig = marketHoldConfiguration.value;
        break;
      case 'risk':
        payload.marketLimitConfiguration = marketLimitConfiguration.value;
        break;
      default:
        break;
      }

      return {
        type: props.configurationType,
        sportTemplateId: props.sportTemplateId,
        payload,
      };
    }

    const saveConfiguration = () => {
      if (isSaveConfigurationDisabled.value) return;
      const config = generateConfig();

      store.dispatch('saveBulkMarketConfiguration', config)
        .then(() => {
          emit('closePopup', 'reload');
          store.dispatch('addNotification', {
            message: 'Market configurations ha been successfully saved.',
            type: 'success',
            duration: 3000,
          });
        })
        .catch((error) => {
          console.log('Saving configuration failed ---> ', error);
        })
        .finally(() => { savingConfiguration.value = false; });
    };

    const onEscEventListener = (e) => {
      if (e.key === 'Escape') {
        if (closePopupOpen.value) {
          closePopupOpen.value = false;
        } else { closePopup(); }
      }
    };
    onMounted(() => document.addEventListener('keydown', onEscEventListener));
    onBeforeUnmount(() => document.removeEventListener('keydown', onEscEventListener));

    const setResultingConfiguration = (newResultingConfiguration) => {
      resultingConfiguration.value = newResultingConfiguration;
      unsavedChanges.value = true;
    };

    const setRiskConfiguration = (newRiskConfiguration) => {
      marketLimitConfiguration.value = newRiskConfiguration;
      unsavedChanges.value = true;
    };

    const setHoldConfiguration = (newHoldConfiguration) => {
      marketHoldConfiguration.value = newHoldConfiguration;
      unsavedChanges.value = true;
    };

    const setTemplateConfiguration = (newTemplateConfiguration) => {
      templateConfiguration.value = newTemplateConfiguration;
      unsavedChanges.value = true;
    };

    return {
      closePopup,
      configurationsPopupRef,
      saveConfiguration,
      closePopupOpen,
      closePopupRef,
      closeSecondPopup,
      savingConfiguration,
      resultingConfiguration,
      modalTitle,
      hasLineMarkets,
      hasRegularMarkets,
      isSaveConfigurationDisabled,
      limitConfigurationType,
      setRiskConfiguration,
      setHoldConfiguration,
      setTemplateConfiguration,
      setResultingConfiguration,
    };
  },
};
</script>

  <style lang="scss">
  @import '@vueform/slider/themes/default.scss';

  .market-configurations-bulk-popup {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    left: 0;
    margin: auto;
    height: 500px;
    width: 770px;
    background-color: #fff;
    border: 1px solid #F0F0F0;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    box-sizing: border-box;
    z-index: $modalZIndex;

    &.close-popup {
      border: 0;
    }

    .market-configurations-header {
      padding: 16px 32px 13px;
      box-shadow: inset 0px -1px 0px #F0F0F0;
      box-sizing: border-box;

      .market-configurations-name {
        height: 30px;
        line-height: 30px;
        font-size: 20px;
        font-weight: 600;
        font-family: 'Poppins';
        color: #191414;
        margin-bottom: 4px;
      }

      .market-configurations-date {
        height: 17px;
        line-height: 17px;
        font-weight: 400;
        font-size: 14px;
        color: #6D6D6D;
      }
    }

    .market-configuration-ps {
      height: 355px;
      overflow-y: auto;
      position: relative;
      z-index: 100;

      .market-configurations-body {
        width: 100%;
        padding: 18px 32px 18px;
        box-sizing: border-box;

        .market-configurations-content {
          width: 100%;
          padding-top: 20px;
          box-sizing: border-box;

          .bounds-wrapper {
            padding-left: 8px;
            box-sizing: border-box;
            width: 100%;
            margin-bottom: 70px;

            .bound-headers {
              width: 100%;
              height: 17px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-weight: 400;
              color: #191414;
              margin-bottom: 12px;
            }

            .bound-content {
              display: flex;
              width: 100%;
              justify-content: space-between;
              height: 33px;

              .bound {
                height: 100%;
                align-items: center;
                color:#6D6D6D;
                font-weight: 400;

                &.fixed-bound {
                  background: #FAFAFA;
                  border: 1px solid #F0F0F0;
                  box-sizing: border-box;
                  border-radius: 4px;
                  color: #A9A9A9;
                  display: flex;
                  align-items: center;
                  padding: 0 8px;
                }
              }
            }
            .offer-checkbox-root {
              display: flex;
              align-items: center;
              gap: 16px;
              padding-top: 16px;
              .offer-checkbox-item {
                display: flex;
                align-items: center;
                gap: 8px;
                span {
                  cursor: pointer;
                }
              }
            }
          }

          .lines-configuration {
            width: 100%;

            .lines-configuration-header {
              display: flex;
              height: 18px;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 12px;

              .lines-configuration-header-tooltip {
                display: flex;
                height: 100%;
                align-items: center;

                .lines-configuration-header-label {
                  color: #000000;
                  font-weight: 600;
                }

                .tooltip {
                  height: 100%;
                  margin-left: 10px;

                  .tooltip-element {
                    height: 16px;
                    width: 16px;
                    display: flex;

                    .icon {
                      height: 16px;
                      width: 16px;
                    }
                  }

                  .tooltip-text {
                    padding: 8px;
                    white-space: nowrap;
                    height: unset;
                  }
                }

              }

              .lines-configuration-information {
                display: flex;
                align-items: center;
                padding-right: 12px;
                cursor: pointer;

                .icon {
                  stroke: #191414;
                  margin-left: 4px;
                }
              }
            }

            .lines-configuration-information-details {
              display: flex;
              height: 65px;
              width: 100%;
              background: #FAFAFA;
              border: 1px solid #F0F0F0;
              box-sizing: border-box;
              border-radius: 4px;
              color: #6D6D6D;
              align-items: center;
              padding:  0 32px;
              margin-bottom: 6px;

              .info-details-value {
                margin: 0 16px 0 8px;
                height: 33px;
                background: #FAFAFA;
                border: 1px solid #F0F0F0;
                box-sizing: border-box;
                border-radius: 4px;
                color: #A9A9A9;
                box-sizing: border-box;
                padding: 0 8px;
                display: flex;
                align-items: center;
                justify-content: center;

                &:last-child {
                  margin-right: 0;
                }
              }
            }

            .skip-lines-row {
              width: 100%;
              height: 64px;
              background: #FFFFFF;
              box-shadow: inset 0px -1px 0px #F0F0F0;
              color: #6D6D6D;
              display: flex;
              align-items: center;
              box-sizing: border-box;
              margin-bottom: 16px;

              .tooltip {
                display: flex;
                align-items: center;
              }

              .market-configuration-input {
                height: 33px;

                &:first-child {
                  margin-left: 8px;
                  margin-right: 8px;
                }
              }
            }

            .lines-configuration-bounds-wrapper {
              width: 100%;

              &.no-skip {
                margin-top: 22px;
              }

              .lines-configuration-bound {
                height: 33px;
                width: 100%;
                color: #6D6D6D;
                font-weight: 400;
                margin-bottom: 12px;
                display: flex;
                align-items: center;

                strong {
                  margin-left: 4px;
                }

                .market-configuration-input {
                  margin: 0 8px;
                }

                .icon {
                  margin-left: 18px;
                  cursor: pointer;
                  svg {
                    stroke: #191414;
                  }
                }
              }

              .period-dropdown-wrapper {
                height: 33px;
                position: relative;
                margin-left: 8px;

                .selected-period {
                  border: 1px solid #DDDDDD;
                  box-sizing: border-box;
                  border-radius: 4px;
                  padding: 0 12px;
                  display: flex;
                  align-items: center;
                  min-width: 165px;
                  height: 100%;
                  justify-content: space-between;
                  cursor: pointer;
                  font-weight: 400;
                  color: #191414;

                  .icon {
                    svg {
                      stroke: #191414;
                    }
                  }
                }

                .period-dropdown {
                  position: absolute;
                  top: 36px;
                  left: 0;
                  width: 100%;
                  background-color: #fff;
                  border: 1px solid #EBEBEB;
                  box-sizing: border-box;
                  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
                  border-radius: 4px;
                  z-index: 103;

                  .dropdown-option {
                    height: 44px;
                    display: flex;
                    align-items: center;
                    padding: 0 12px;
                    box-sizing: border-box;
                    cursor: pointer;
                    color: #191414;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;

                    &:hover {
                      background-color: rgba(0, 0, 0, 0.03);
                    }
                  }
                }
              }

              .define-lines-button {
                height: 33px;
                border: 1px solid #DDDDDD;
                box-sizing: border-box;
                border-radius: 4px;
                padding: 0 12px;
                color: #191414;
                display: flex;
                align-items: center;
                width: fit-content;
                cursor: pointer;
                margin-bottom: 58px;

                &:hover {
                  background-color: rgba(0, 0, 0, 0.03);
                }

                .icon {
                  height: 14px;
                  width: 14px;
                  margin-left: 4px;

                  svg {
                    stroke: #191414;
                  }
                }
              }
            }
          }

          .market-configuration-input {
            height: 100%;
            width: 44px;
            color: #191414;
            border-radius: 4px;
            outline: none;
            line-height: 33px;
            border: 1px solid #CDCDCD;
            box-sizing: border-box;
            margin: 0 4px 0 8px;
            padding: 0 4px;
            text-align: center;

            &:first-child {
              margin-left: 0;
            }
          }

          .risk-configuration-section {
            width: 100%;
            margin-bottom: 16px;
            display: flex;

            .risk-configuration-group {
              margin-right: 16px;
              display: flex;
              flex-direction: column;

              .label {
                color: #A9A9A9;
                font-size: 10px;
                font-weight: 600;
                margin-bottom: 4px;
              }

              .risk-dropdown {
                width: 220px;

                .dropdown {
                  z-index: unset;

                  .dropdown-button {
                    border-color: #DDDDDD;
                  }
                }

                .dropdown-button {
                  width: 100%;
                  justify-content: space-between;
                }
              }

              .risk-input {
                display: flex;

                .increase-decrease-button {
                  height: 33px;
                  width: 33px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border: 1px solid #DDDDDD;
                  cursor: pointer;

                  &.left {
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                  }

                  &.right {
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                  }

                  svg {
                    path {
                      stroke: #191414;
                    }
                  }
                }

                .table-number-input {
                  width: 94px;

                  input {
                    text-align: center;
                    border-color: #DDDDDD;
                    height: 33px;
                    border-right: 0;
                    border-left: 0;
                    border-radius: 0;
                  }
                }
                .us-cent-dd {
                  width: 120px;
                }
              }
            }
          }
        }
      }
    }

    .market-configurations-footer {
      height: 65px;
      width: 100%;
      box-shadow: inset 0px 1px 0px #F0F0F0;
      padding: 0 32px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .market-configurations-button {
        height: 33px;
        cursor: pointer;
        display: flex;
        align-items: center;
        color: #191414;
        width: 120px;
        justify-content: center;
        font-weight: 400;
        border-radius: 4px;

        &:hover {
          background-color: rgba(#000, 0.05);
        }

        &.save {
          background-color: #003C3C;
          margin-left: 5px;
          color: #fff;
        }

        &.disabled {
          opacity: 0.8;
          cursor: default;
        }
      }
    }

    .tooltip {
      height: 100%;
      margin-left: 10px;

      .tooltip-element {
        height: 16px;
        width: 16px;
        display: flex;

        .icon {
          height: 16px;
          width: 16px;
        }
      }

      .tooltip-text {
        padding: 8px;
        white-space: nowrap;
        height: unset;
      }
    }

    input[type='number'] {
      -moz-appearance:textfield;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    .close-popup-wrapper {
      position: absolute;
      width: 440px;
      height: 151px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      background: #FFFFFF;
      border: 1px solid #F0F0F0;
      box-sizing: border-box;
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
      z-index: 310;
      padding: 16px;

      .unsaved-changes-header {
        color: #191414;
        font-weight: 600;
        margin-bottom: 12px;
      }

      .unsaved-changes-message {
        margin-bottom: 32px;
      }

      .unsaved-changes-buttons {
        height: 33px;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .unsaved-changes-button {
          height: 100%;
          width: 120px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          border-radius: 4px;

          &:hover {
            background-color: rgba(#000, 0.05);
          }

          &.yes {
            color: #fff;
            background: #003C3C;
            margin-left: 5px;
          }
        }
      }
    }

    .close-current-popup-bg-overlay {
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: rgba(#000, 0.5);
      z-index: 105;
      left: 0;
      border-radius: 8px;
    }
  }

  .close-popup-bg-overlay {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: rgba(#000, 0.5);
    z-index: $modalBackgroundZIndex;
    left: 0;
    top: 0;
  }
  </style>
