<template>
  <div
    class="market-selection-propmpt-context-menu"
  >
    <div
      class="market-selection-propmpt-context-menu__action"
      v-for="item in items"
      :key="item.key"
      @click="onItemSelect(item)"
    >
      {{ item.label }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  emits: ['onItemSelect'],
  setup(_, { emit }) {
    const onItemSelect = (item) => {
      emit('onItemSelect', item);
    };
    return {
      onItemSelect,
    };
  },
};
</script>

  <style lang="scss">
  .market-selection-propmpt-context-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: $white;
    color: $black;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    border: 1px solid rgba(221, 221, 221, 1);
    z-index: $dropdownMenuZIndex;
    bottom: calc(100% + 10px);
    right: 0;

    &__action {
      display: inline-flex;
      align-items: center;
      white-space: nowrap;
      min-width: 100%;
      height: 44px;
      padding: 0 12px;
      box-shadow: inset 0px -1px 0px #F0F0F0;
      cursor: pointer;

      &:last-of-type {
        box-shadow: none;
      }

      &:hover,
      &:focus {
        background-color: #FAFAFA;
        outline: none;
      }
    }
  }
  </style>
