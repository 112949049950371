<template>
  <transition name="markets-page-prompt">
    <div
      v-show="hasSelectedMarkets"
      class="markets-page-prompt"
    >
      <div
        v-if="selectedMarketsLabel"
        class="markets-page-prompt-box"
      >
        {{ selectedMarketsLabel }}
      </div>
      <div class="markets-page-prompt-box">
        <Button
          v-if="hasSelectedMarkets"
          variant="tertiary"
          @click="clearSelection"
        >
          Clear selection
        </Button>
        <Button
          v-if="hasSelectedMarkets"
          variant="primary"
          @click="toggleConfigureContextMenu"
          :icon="'settings'"
          class="open-configure-button"
        >
          Configure
          <MarketSelectionPromptContextMenu
            v-if="marketSelectionContextMenuVisible"
            :items="marketSelectionContextMenuItems"
            @onItemSelect="openBulkConfigureModal"
          />
        </Button>
      </div>
    </div>
  </transition>
</template>

<script>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { filter } from 'lodash';
import Button from '@/components/common/Button';
import MarketSelectionPromptContextMenu from './MarketSelectionPromptContextMenu';

export default {
  components: {
    Button,
    MarketSelectionPromptContextMenu,
  },
  emits: ['onBulkConfigureMarket:option'],
  setup(props, { emit }) {
    const store = useStore();

    const processing = ref(false);
    const selectedMarkets = computed(() => store.getters.selectedMarkets);
    const hasSelectedMarkets = computed(() => !!selectedMarkets.value.length);
    const lineMarkets = computed(() => filter(selectedMarkets.value, (selectedMarket) => selectedMarket.isLineMarket));
    const hasLineMarkets = computed(() => !!lineMarkets.value.length);
    const hasRegularMarkets = computed(() => lineMarkets.value.length !== selectedMarkets.value.length);
    const marketSelectionContextMenuVisible = ref(false);
    const marketSelectionContextMenuItems = computed(() => {
      const items = [];
      if ((hasLineMarkets.value && !hasRegularMarkets.value) || (hasRegularMarkets.value && !hasLineMarkets.value)) {
        items.push({
          key: 'risk',
          label: 'Risk',
        });
      }
      items.push({
        key: 'hold',
        label: 'Hold',
      });
      if (hasLineMarkets.value && !hasRegularMarkets.value) {
        items.push({
          key: 'line',
          label: 'Line configuration',
        });
      }
      items.push({
        key: 'resulting',
        label: 'Resulting',
      });

      return items;
    });
    const toggleConfigureContextMenu = () => {
      marketSelectionContextMenuVisible.value = !marketSelectionContextMenuVisible.value;
    };

    const selectedMarketsLabel = computed(() => {
      let label = `${selectedMarkets.value.length} market`;
      if (!selectedMarkets.value.length) label = 'No markets';
      if (selectedMarkets.value?.length > 1) label += 's';
      return `${label} selected`;
    });

    const openBulkConfigureModal = (selectedItem) => {
      emit('onBulkConfigureMarket:option', selectedItem.key);
    };

    const clearSelection = () => {
      store.dispatch('updateSelectedMarkets', []);
    };

    watch(
      () => hasSelectedMarkets.value, (newValue) => {
        if (!newValue) {
          marketSelectionContextMenuVisible.value = false;
        }
      },
    );

    return {
      selectedMarketsLabel,
      clearSelection,
      processing,
      selectedMarkets,
      hasSelectedMarkets,
      openBulkConfigureModal,
      marketSelectionContextMenuItems,
      toggleConfigureContextMenu,
      marketSelectionContextMenuVisible,
    };
  },
};
</script>

<style lang="scss">
.markets-page-prompt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 16px;
  position: absolute;
  background: #FFFFFF;
  border: 1px solid $gray600;
  box-sizing: border-box;
  box-shadow: 0px 8px 12px -6px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  left: 50%;
  transform: translateX(-50%);
  transform-origin: center;
  max-width: 55%;
  min-width: 400px;
  height: 65px;
  bottom: 80px;
  z-index: 1110;
}

.markets-page-prompt-enter-active {
  animation: markets-page-prompt-bounce-in .5s;
}

.markets-page-prompt-leave-active {
  animation: markets-page-prompt-bounce-in .25s reverse;
}

@keyframes markets-page-prompt-bounce-in {
  0% {
    transform: translateY(100%) translateX(-50%);
  }
  50% {
    transform: translateY(-10%) translateX(-50%);
  }
  100% {
    transform: translateY(0%) translateX(-50%);
  }
}

.markets-page-prompt-box {
  display: flex;
  align-items: center;

  & > * {
    margin: 0 7px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
.markets-page-prompt-box:nth-child(1) {
  margin-right: 2rem;
}
.open-configure-button {
  position: relative;
  .icon svg path {
    fill: #FFFFFF;
  }
}
</style>
